
import type { PropType } from 'vue'
import { defineComponent, toRefs } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import MessageStatus from '@/components/pages/history/MessageStatus.vue'
import TmSmsRecipient from '@/components/shared/TmSmsRecipient.vue'
import SkeletonPerson from '@/components/shared/skeleton/SkeletonPerson.vue'
import TmSkeleton from '@/components/shared/TmSkeleton.vue'
import SmsSentDropdown from '@/components/pages/history/smsSent/SmsSentDropdown.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import type { SMSReceivedSent } from '@/definitions/history/types'
import type { RouteLocationRaw } from 'vue-router'
import { useModals } from '@/compositions/modals'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    TmTable,
    TmBadge,
    TmSkeleton,
    SmsSentDropdown,
    SkeletonPerson,
    TmSmsRecipient,
    MessageStatus,
  },
  props: {
    headers: {
      type: Array as PropType<TableHeaders[]>,
      required: true,
    },
    items: {
      type: Array as PropType<SMSReceivedSent[]>,
      required: true,
    },
    itemsName: {
      type: String,
      required: true,
    },
    itemKey: {
      type: String,
      default: 'id',
    },
    showSelection: {
      type: Boolean,
    },
    expand: {
      type: Boolean,
    },
    detailsModal: {
      type: Boolean,
    },
  },
  setup(props) {
    const { isLoadingMode } = useModes()
    const { detailsModal } = toRefs(props)
    const { openModal } = useModals()

    const getTo = (id: string): RouteLocationRaw | undefined => (detailsModal.value)
      ? undefined
      : {
          name: 'base.history.sentSms.details',
          params: {
            id,
          },
        }

    const openModalFunc = (message: SMSReceivedSent) => {
      openModal('historySmsInfo', { message })
    }

    return {
      getTo,
      openModal,
      openModalFunc,
      isLoadingMode,
    }
  },
})
