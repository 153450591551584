
import type { PropType } from 'vue'
import {
  computed, defineComponent
} from 'vue'
import SmsSentDetailsMessage from '@/components/pages/history/smsSent/details/SmsSentDetailsMessage.vue'
import DetailsTable from '@/components/shared/templates/details/DetailsTable.vue'
import DetailsTableItem from '@/components/shared/templates/details/DetailsTableItem.vue'
import TmSmsRecipient from '@/components/shared/TmSmsRecipient.vue'
import TmCountry from '@/components/shared/TmCountry.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import TmCountriesRow from '@/components/shared/TmCountriesRow.vue'
import type { SMSReceivedSent } from '@/definitions/history/types'

export default defineComponent({
  components: {
    DetailsTable,
    SmsSentDetailsMessage,
    DetailsTableItem,
    TmStatus,
    TmSmsRecipient,
    TmCountry,
    TmCountriesRow,
    TmBadge,
  },
  props: {
    message: {
      type: Object as PropType<SMSReceivedSent>,
      required: true,
    },
  },
  setup(props) {
    const isMMS = computed(() => typeof props.message.message === 'object' && props.message.message.extension === 'png')
    const isVoice = computed(() => typeof props.message.message === 'object' && props.message.message.extension === 'mp3')
    const isPlain = computed(() => typeof props.message.message === 'string')
    const encoding = computed(() => {
      if (isMMS.value) { return 'MMS' }
      if (isVoice.value) { return 'Voice' }
      return 'Plain text'
    })

    return {
      isMMS,
      isVoice,
      isPlain,
      encoding,
    }
  },
})
