import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a856a86"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-tabs" }
const _hoisted_2 = { class: "d-flex justify-between" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_tabs_nav = _resolveComponent("tm-tabs-nav")!
  const _component_tm_tabs_panels = _resolveComponent("tm-tabs-panels")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_tm_tabs_nav, {
        "model-value": _ctx.modelValue !== null ? _ctx.modelValue : _ctx.state,
        tabs: _ctx.tabs,
        "no-border": _ctx.noBorder,
        "tabs-grow": _ctx.tabsGrow,
        size: _ctx.size,
        "tab-style": _ctx.tabStyle,
        vertical: _ctx.vertical,
        "use-router": _ctx.useRouter,
        "with-dropdown": _ctx.withDropdown,
        "max-visible-items": _ctx.maxVisibleItems,
        "show-counter-zero": _ctx.showCounterZero,
        class: _normalizeClass(["flex-grow-1", _ctx.tabsNavClass]),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.modelValue !== null ? _ctx.$emit('update:modelValue', $event) : _ctx.state = $event))
      }, null, 8, ["model-value", "tabs", "no-border", "tabs-grow", "size", "tab-style", "vertical", "use-router", "with-dropdown", "max-visible-items", "show-counter-zero", "class"]),
      (_ctx.hasTabsNavRightSlot)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "tabs-nav-right", {}, undefined, true)
          ]))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.hidePanels)
      ? (_openBlock(), _createBlock(_component_tm_tabs_panels, {
          key: 0,
          "model-value": _ctx.modelValue !== null ? _ctx.modelValue : _ctx.state,
          tabs: _ctx.tabs,
          class: "mt-4",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.modelValue !== null ? _ctx.$emit('update:modelValue', $event) : _ctx.state = $event))
        }, _createSlots({ _: 2 }, [
          _renderList(_ctx.$slots, (_, name) => {
            return {
              name: name,
              fn: _withCtx((data) => [
                _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(data)), undefined, true)
              ])
            }
          })
        ]), 1032, ["model-value", "tabs"]))
      : _createCommentVNode("", true)
  ]))
}