
import { defineComponent } from 'vue'
import TmAutoSizePanel from '@/components/shared/autoSize/TmAutoSizePanel.vue'
import TmAutoSizePanelMobileActivator from '@/components/shared/autoSize/panel/TmAutoSizePanelMobileActivator.vue'
import TmAutoSizePanelItemButton from '@/components/shared/autoSize/panel/TmAutoSizePanelItemButton.vue'
import SmsDetailsMoreActions from '@/components/pages/history/details/SmsDetailsMoreActions.vue'

export default defineComponent({
  components: {
    TmAutoSizePanel,
    TmAutoSizePanelItemButton,
    TmAutoSizePanelMobileActivator,
    SmsDetailsMoreActions,
  },
})
