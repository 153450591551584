
import type { PropType } from 'vue'
import { defineComponent, toRefs } from 'vue'
import useHistorySmsOpenModal from '@/compositions/history/useHistorySmsOpenModal'
import TmAutoSizePanel from '@/components/shared/autoSize/TmAutoSizePanel.vue'
import TmAutoSizePanelItemButton from '@/components/shared/autoSize/panel/TmAutoSizePanelItemButton.vue'
import type { SMSReceivedSent } from '@/definitions/history/types'
import TmAutoSizePanelMobileActivator from '@/components/shared/autoSize/panel/TmAutoSizePanelMobileActivator.vue'

export default defineComponent({
  components: {
    TmAutoSizePanel,
    TmAutoSizePanelMobileActivator,
    TmAutoSizePanelItemButton,
  },
  props: {
    message: {
      type: Object as PropType<SMSReceivedSent>,
      required: true,
    },
  },
  setup(props) {
    const { message } = toRefs(props)

    const { openHistoryDeleteModal } = useHistorySmsOpenModal({
      lines: message,
      type: 'sent',
    })

    return {
      openHistoryDeleteModal,
    }
  },
})
