import type { ContactBlocked } from '@/definitions/contacts/blocked/types'
import { useModals } from '@/compositions/modals'

type UseBlockedOpenModal = () => {
  openModalUnblock: (name: string[] | string) => void
  openModalForm: (contact?: ContactBlocked, isEdit?: boolean) => void
}

const useBlockedOpenModal: UseBlockedOpenModal = () => {
  const { openModal } = useModals()

  const openModalUnblock = (name: string[] | string) => {
    openModal(
      'contactsBlockedUnblock',
      {
        name: (typeof name === 'string') ? [name] : name,
      }
    )
  }

  const openModalForm = (contact?: ContactBlocked, isEdit?: boolean) => {
    openModal(
      'contactsBlockedForm',
      {
        ...(contact && { contact }),
        modalTitle: isEdit ? 'Edit blocked number' : 'Add blocked number',
      }
    )
  }

  return {
    openModalUnblock,
    openModalForm,
  }
}

export default useBlockedOpenModal
