
import type { PropType } from 'vue'
import { computed, defineComponent } from 'vue'
import TmAudioPlayer from '@/components/shared/TmAudioPlayer.vue'
import { useModals } from '@/compositions/modals'
import type { SMSReceivedSent } from '@/definitions/history/types'

export default defineComponent({
  components: {
    TmAudioPlayer,
  },
  props: {
    message: {
      type: Object as PropType<SMSReceivedSent>,
      required: true,
    },
  },
  setup(props) {
    const isMMS = computed(() => typeof props.message.message === 'object' && props.message.message.extension === 'png')
    const isVoice = computed(() => typeof props.message.message === 'object' && props.message.message.extension === 'mp3')
    const isPlain = computed(() => typeof props.message.message === 'string')

    const { openModal } = useModals()
    const openImageAttachmentModal = (url: string) => {
      openModal('chatsImageAttachment', {
        url: url,
      })
    }

    return {
      openImageAttachmentModal,
      isMMS,
      isVoice,
      isPlain,
    }
  },
})
