
import { defineComponent, computed } from 'vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import type { PropType } from 'vue'

type MessageStatusType = { name: 'rejected'; color: 'orange'; outline: boolean; } |
 { name: 'failed'; color: 'red'; outline: boolean; } |
 { name: 'delivered'; color: 'green'; outline: boolean; } |
 { name: 'sent'; color: 'blue'; outline: boolean; }

export default defineComponent({
  components: {
    TmStatus,
    TmTooltip,
  },
  props: {
    status: {
      type: Object as PropType<MessageStatusType>,
      required: true,
    },
    recipients: {
      type: Boolean,
    },
    plain: {
      type: Boolean,
    },
    rejectReason: {
      type: String,
      default: 'This contact has unsubscribed from your lists.',
    },
  },
  setup(props) {
    const tooltip = computed(() => {
      switch (props.status.name) {
        case 'rejected': return 'Message had not been sent because it was rejected by Textmagic.'
        case 'failed': return 'Message was not delivered. Possible reasons: phone switched off, out of coverage, incorrect number.'
        case 'delivered': return 'Message has been successfully delivered to the recipient.'
        default: return 'Message has been sent. Waiting for final delivery confirmation from mobile operator.'
      }
    })

    return {
      tooltip,
    }
  },
})
