
import type { PropType } from 'vue'
import {
  defineComponent, computed, ref, toRefs
} from 'vue'
import SmsSentTable from '@/components/pages/history/smsSent/SmsSentTable.vue'
import SmsSentDetailsMultiSummary from '@/components/pages/history/smsSent/details/SmsSentDetailsMultiSummary.vue'
import SmsSentDetailsMultiFilter from '@/components/pages/history/smsSent/details/SmsSentDetailsMultiFilter.vue'
import SmsSentDetailsMultiSummaryButtons from '@/components/pages/history/smsSent/details/SmsSentDetailsMultiSummaryButtons.vue'
import TmTabs from '@/components/shared/TmTabs.vue'
import { getTableData } from '@/services/tableService'
import type { SMSReceivedSent } from '@/definitions/history/types'
import type { Tab, TableHeaders } from '@/definitions/shared/types'
import { formatDate } from '@/services/dateTimeService'

export default defineComponent({
  components: {
    SmsSentDetailsMultiFilter,
    SmsSentDetailsMultiSummary,
    SmsSentDetailsMultiSummaryButtons,
    SmsSentTable,
    TmTabs,
  },
  props: {
    message: {
      type: Object as PropType<SMSReceivedSent>,
      required: true,
    },
    hideTabs: {
      type: Boolean,
    },
  },
  setup(props) {
    const { message } = toRefs(props)

    const search = ref('')

    const tabsVariant = ref<Tab[]>([
      {
        name: '',
        label: 'Summary',
        to: {
          name: 'base.history.sentSms.details',
          params: {
            id: message.value.id,
          },
        },
      },
      {
        name: 'delivered',
        label: 'Delivered',
        counterClass: 'green--text font-weight-semi-bold',
        to: {
          name: 'base.history.sentSms.details',
          params: {
            id: message.value.id,
            subPage: 'delivered',
          },
        },
      },
      {
        name: 'sent',
        label: 'Sent',
        counterClass: 'font-weight-semi-bold',
        to: {
          name: 'base.history.sentSms.details',
          params: {
            id: message.value.id,
            subPage: 'sent',
          },
        },
      },
      {
        name: 'failed',
        label: 'Failed',
        counterClass: 'red--text font-weight-semi-bold',
        to: {
          name: 'base.history.sentSms.details',
          params: {
            id: message.value.id,
            subPage: 'failed',
          },
        },
      },
      {
        name: 'rejected',
        label: 'Rejected',
        counterClass: 'orange--text font-weight-semi-bold',
        to: {
          name: 'base.history.sentSms.details',
          params: {
            id: message.value.id,
            subPage: 'rejected',
          },
        },
      },
    ])

    const tableItemsAll = computed<SMSReceivedSent[]>(() => (getTableData('historySMS') as SMSReceivedSent[])
      .filter((line) => (line.historyType === 'sent' && line.status.name !== 'recipients')))

    const tabs = computed<Tab[]>(() => tabsVariant.value.map((el) => {
      if (el.name === '') return el
      return {
        ...el,
        counter: tableItemsAll.value.filter((item) => (item.status.name === el.name)).length,
      }
    }))

    const tabsResult = ref('')

    const tableItems = computed<SMSReceivedSent[]>(() => tableItemsAll.value.filter(
      (item) => (item.status.name === tabsResult.value)
    ))

    const tableItemsFiltered = computed(() => {
      if (!search.value) return tableItems.value
      return tableItems.value.filter((l) => l.message.toLowerCase().includes(search.value.toLowerCase()))
    })

    const expand = ref(false)

    const tableHeaders = ref<TableHeaders[]>([
      { text: 'To', value: 'to-slot' },
      {
        text: 'Message',
        value: 'message',
        expandPossible: true,
        width: '50%',
      },
      { text: 'Status', value: 'status-slot' },
      {
        text: 'Send time',
        value: 'sendTime',
        format: (val: string) => formatDate(val),
      },
    ])

    return {
      tabs,
      tabsResult,
      tableItemsFiltered,
      expand,
      tableHeaders,
      tabsVariant,
      search,
    }
  },
})
