import type { Ref } from 'vue'
import { computed } from 'vue'
import { useModals } from '@/compositions/modals'
import type { SMSReceivedSent } from '@/definitions/history/types'

type UseHistorySmsOpenModalPayload = {
  lines: Ref<SMSReceivedSent[]> | Ref<SMSReceivedSent>
  type: 'sent' | 'received'
}

type UseHistorySmsOpenModal = (payload: UseHistorySmsOpenModalPayload) => {
  openHistoryDeleteModal: () => void
  openHistoryFilterSms: () => void
}

const useHistorySmsOpenModal: UseHistorySmsOpenModal = ({ lines, type }) => {
  const { openModal } = useModals()

  const messageWord = computed(() => (Array.isArray(lines.value) && lines.value.length > 1) ? 'messages' : 'message')

  const titleDelete = computed(() => `Delete ${type} ${messageWord.value}`)

  const textDelete = computed(() => `Are you sure you would like to delete selected ${type} ${messageWord.value}? This action cannot be undone.`)

  const openHistoryDeleteModal = () => {
    openModal('confirmation', {
      title: titleDelete,
      text: textDelete,
      btnText: 'Delete',
      btnColor: 'red',
    })
  }

  const titleFilter = computed(() => type === 'received' ? 'Inbound SMS filter' : 'Outbound SMS filter')

  const checkBoxLabelFilter = computed(() => (type === 'sent')
    ? 'Messages with no replies'
    : 'Received messages with no outbound sent'
  )

  const textFilter = computed(() => (type === 'sent')
    ? 'Display only the sent messages with no replies received.'
    : 'Display only the received messages with no outbound message sent beforehand.'
  )

  const openHistoryFilterSms = () => {
    openModal('historySmsFilter', {
      title: titleFilter,
      text: textFilter,
      checkboxLabel: checkBoxLabelFilter,
    })
  }

  return {
    openHistoryDeleteModal,
    openHistoryFilterSms,
  }
}

export default useHistorySmsOpenModal
