import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-inline-flex align-center flex-wrap" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = {
  key: 1,
  class: "d-inline-block lh-20 distinct--text body-text-regular-14"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_tm_tooltip = _resolveComponent("tm-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_status, _mergeProps({ class: "mr-2" }, {
        ..._ctx.status,
        color: _ctx.recipients ? 'blue-fill' : _ctx.status.color
      }), null, 16),
    (!_ctx.recipients && _ctx.plain)
      ? (_openBlock(), _createBlock(_component_tm_tooltip, { key: 0 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.tooltip), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.status.name === 'rejected' && !_ctx.plain)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.rejectReason), 1))
      : _createCommentVNode("", true)
  ]))
}