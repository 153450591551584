
import type { PropType } from 'vue'
import { defineComponent, computed } from 'vue'
import type { RouteLocationRaw } from 'vue-router'
import type { SmsToVariant } from '@/definitions/_general/_types/sms'
import TmPerson from '@/components/shared/TmPerson.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  name: 'TmSmsRecipient',
  components: {
    TmButton,
    TmPerson,
  },
  props: {
    showAddBtn: {
      type: Boolean,
    },
    content: {
      type: Object as PropType<SmsToVariant>,
      required: true,
    },
    to: {
      type: Object as PropType<RouteLocationRaw>,
    },
  },
  setup(props) {
    const { openModal } = useModals()
    const name = computed(() => {
      if (props.content.type === 'contact') {
        if (!!props.content.firstName && !!props.content.lastName) { return `${props.content.firstName} ${props.content.lastName}` }
        return props.content.phone
      }
      if (props.content.type === 'contacts') return `${props.content.count} phone numbers`
      if (props.content.type === 'recipient') return props.content.phone
      return `${props.content.count} recipients`
    })
    const avatarColor = computed(() => {
      if (props.content.type === 'recipients') return 'blue-400'
      if (props.content.type === 'contacts') return 'gray'
      return props.content.avatarColor
    })

    return {
      name,
      avatarColor,
      openModal,
    }
  },
})
