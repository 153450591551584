
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

type Tab = {
  name: string;
  counter?: number;
  label?: string;
  to?: string;
}

export default defineComponent({
  name: 'TpTabsPanels',
  props: {
    tabs: {
      type: Array as PropType<Tab[]>,
      required: true,
    },
    modelValue: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
})
