import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-530456f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-tabs__panels" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_tab_panels, {
      animated: "",
      "model-value": _ctx.modelValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, i) => {
          return (_openBlock(), _createBlock(_component_q_tab_panel, {
            key: i,
            name: tab.name
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, `panel-${tab.name}`, {}, undefined, true)
            ]),
            _: 2
          }, 1032, ["name"]))
        }), 128))
      ]),
      _: 3
    }, 8, ["model-value"])
  ]))
}