import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a3adbff0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sms_sent_details_single_buttons = _resolveComponent("sms-sent-details-single-buttons")!
  const _component_details_table_item = _resolveComponent("details-table-item")!
  const _component_tm_country = _resolveComponent("tm-country")!
  const _component_tm_list_chip_recipient = _resolveComponent("tm-list-chip-recipient")!
  const _component_sms_sent_details_message = _resolveComponent("sms-sent-details-message")!
  const _component_message_status = _resolveComponent("message-status")!
  const _component_details_table = _resolveComponent("details-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "buttons", {}, () => [
        _createVNode(_component_sms_sent_details_single_buttons, { message: _ctx.message }, null, 8, ["message"])
      ], true)
    ]),
    _createVNode(_component_details_table, null, {
      default: _withCtx(() => [
        _createVNode(_component_details_table_item, {
          label: "Message ID",
          value: "6088849"
        }),
        _createVNode(_component_details_table_item, {
          label: "Send time",
          value: "8 Jul 10:44 am"
        }),
        _createVNode(_component_details_table_item, { label: "From" }, {
          default: _withCtx(() => [
            _createVNode(_component_tm_country, { country: {
            id: 'us',
            name: '+37259122132',
            counter: 'US Support',
          } })
          ]),
          _: 1
        }),
        _createVNode(_component_details_table_item, {
          label: "To",
          "label-align-center": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_tm_list_chip_recipient, {
              recipient: _ctx.message.to
            }, null, 8, ["recipient"])
          ]),
          _: 1
        }),
        _createVNode(_component_details_table_item, { label: "Message content" }, {
          default: _withCtx(() => [
            _createVNode(_component_sms_sent_details_message, { message: _ctx.message }, null, 8, ["message"])
          ]),
          _: 1
        }),
        _createVNode(_component_details_table_item, {
          label: "Source",
          value: "SMS Chat"
        }),
        (_ctx.isPlain)
          ? (_openBlock(), _createBlock(_component_details_table_item, {
              key: 0,
              label: "Total characters",
              value: `${_ctx.message.message.length} characters`
            }, null, 8, ["value"]))
          : _createCommentVNode("", true),
        (_ctx.isPlain)
          ? (_openBlock(), _createBlock(_component_details_table_item, {
              key: 1,
              label: "Message parts",
              value: "1 part"
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_details_table_item, {
          label: "Encoding",
          value: _ctx.encoding
        }, null, 8, ["value"]),
        (!_ctx.hideStatus)
          ? (_openBlock(), _createBlock(_component_details_table_item, {
              key: 2,
              label: "Status",
              "label-align-center": ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_message_status, {
                  status: _ctx.message.status
                }, null, 8, ["status"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_details_table_item, {
          label: "Total cost",
          value: "$0.066"
        })
      ]),
      _: 1
    })
  ]))
}