import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_tabs = _resolveComponent("tm-tabs")!
  const _component_sms_sent_details_multi_summary_buttons = _resolveComponent("sms-sent-details-multi-summary-buttons")!
  const _component_sms_sent_details_multi_filter = _resolveComponent("sms-sent-details-multi-filter")!
  const _component_sms_sent_details_multi_summary = _resolveComponent("sms-sent-details-multi-summary")!
  const _component_sms_sent_table = _resolveComponent("sms-sent-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.hideTabs)
      ? (_openBlock(), _createBlock(_component_tm_tabs, {
          key: 0,
          modelValue: _ctx.tabsResult,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tabsResult) = $event)),
          tabs: _ctx.tabs,
          class: "mb-4",
          "use-router": ""
        }, null, 8, ["modelValue", "tabs"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "buttons", {}, () => [
        (!_ctx.tabsResult)
          ? (_openBlock(), _createBlock(_component_sms_sent_details_multi_summary_buttons, {
              key: 0,
              message: _ctx.message
            }, null, 8, ["message"]))
          : (_openBlock(), _createBlock(_component_sms_sent_details_multi_filter, {
              key: 1,
              expand: _ctx.expand,
              "onUpdate:expand": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.expand) = $event)),
              search: _ctx.search,
              "onUpdate:search": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.search) = $event)),
              message: _ctx.message
            }, null, 8, ["expand", "search", "message"]))
      ])
    ]),
    (!_ctx.tabsResult)
      ? (_openBlock(), _createBlock(_component_sms_sent_details_multi_summary, {
          key: 1,
          message: _ctx.message
        }, null, 8, ["message"]))
      : (_openBlock(), _createBlock(_component_sms_sent_table, {
          key: 2,
          class: "mt-3",
          headers: _ctx.tableHeaders,
          items: _ctx.tableItemsFiltered,
          "item-key": "id",
          "items-name": "SMS",
          expand: _ctx.expand,
          "details-modal": ""
        }, null, 8, ["headers", "items", "expand"]))
  ]))
}