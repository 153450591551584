import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_dropdown_download = _resolveComponent("tm-dropdown-download")!
  const _component_top_filter_button_filter = _resolveComponent("top-filter-button-filter")!
  const _component_top_filter = _resolveComponent("top-filter")!

  return (_openBlock(), _createBlock(_component_top_filter, null, {
    "filter-line-left-default": _withCtx(() => [
      _createVNode(_component_tm_dropdown_download)
    ]),
    "filter-line-right-after-search": _withCtx(() => [
      _createVNode(_component_top_filter_button_filter, { onClick: _ctx.openHistoryFilterSms }, null, 8, ["onClick"])
    ]),
    _: 1
  }))
}