
import { defineComponent, computed } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import type { PropType } from 'vue'
import type { ListChipRecipient } from '@/definitions/shared/types'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    TmButton,
    TmBadge,
  },
  props: {
    recipient: {
      type: Object as PropType<ListChipRecipient>,
      required: true,
    },
  },
  setup(props) {
    const { openModal } = useModals()
    const withoutName = computed(() => !props.recipient.firstName && !props.recipient.lastName)
    const name = computed(() => {
      if (props.recipient.type === 'list') { return props.recipient.name }
      if (props.recipient.type === 'recipient' || withoutName.value) { return props.recipient.phone }
      return `${props.recipient.firstName} ${props.recipient.lastName}`
    })

    const hint = computed(() => {
      if (props.recipient.type === 'list') { return props.recipient.counter }
      if (props.recipient.type === 'contact' && !withoutName.value) { return props.recipient.phone }
      return ''
    })

    const icon = computed(() => {
      if (props.recipient.type === 'recipient') { return }
      return props.recipient.type === 'contact' ? 'person' : 'people'
    })

    return {
      hint,
      name,
      icon,
      openModal,
      withoutName,
    }
  },
})
