
import {
  computed,
  defineComponent
} from 'vue'
import { useRoute } from 'vue-router'
import SmsSentDetailsSingle from '@/components/pages/history/smsSent/details/SmsSentDetailsSingle.vue'
import SmsSentDetailsMulti from '@/components/pages/history/smsSent/details/SmsSentDetailsMulti.vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import TmButton from '@/components/shared/TmButton.vue'
import type { BreadcrumbsLink } from '@/definitions/shared/types'
import type { SMSReceivedSent } from '@/definitions/history/types'
import { getTableData } from '@/services/tableService'
import { formatDate } from '@/services/dateTimeService'
import { useModes } from '@/compositions/modes'
import useBlockedOpenModal from '@/compositions/contacts/blocked/useBlockedOpenModal'
import useContactsOpenModal from '@/compositions/contacts/useContactsOpenModal'
import PageContent from '@/components/layout/pageContent/PageContent.vue'

const breadcrumbsLink: BreadcrumbsLink[] = [
  {
    label: 'Outbound SMS',
    name: 'base.history.sentSms',
  },
  {
    label: '6088849',
  },
]

export default defineComponent({
  components: {
    PageContent,
    TmAlert,
    TmButton,
    SmsSentDetailsSingle,
    SmsSentDetailsMulti,
  },
  setup() {
    const route = useRoute()
    const { isErrorMode } = useModes()
    const { openModalUnblock } = useBlockedOpenModal()
    const { openResubscribeContactModal } = useContactsOpenModal()

    const message = computed(() => (getTableData('historySMS') as SMSReceivedSent[])
      .find(el => el.id === route.params.id))

    return {
      isErrorMode,
      breadcrumbsLink,
      openModalUnblock,
      openResubscribeContactModal,
      message,
      formatDate,
    }
  },
})
