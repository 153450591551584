import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "w100pr" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "mt-1 d-flex body-text-regular-14 lh-20" }
const _hoisted_4 = { class: "distinct--text ml-1" }
const _hoisted_5 = { class: "lh-20 mb-1" }
const _hoisted_6 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_audio_player = _resolveComponent("tm-audio-player")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isMMS)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("img", {
            class: "border-radius d-block",
            src: _ctx.message.message.url,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openImageAttachmentModal(_ctx.message.message.url)))
          }, null, 8, _hoisted_2),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, _toDisplayString(_ctx.message.message.name) + "." + _toDisplayString(_ctx.message.message.extension), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.message.message.size), 1)
          ])
        ], 64))
      : (_ctx.isVoice)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.message.message.name) + "." + _toDisplayString(_ctx.message.message.extension), 1),
            _createVNode(_component_tm_audio_player, {
              duration: _ctx.message.message.duration
            }, null, 8, ["duration"])
          ], 64))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.message.message), 1))
  ]))
}