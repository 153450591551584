
import type { PropType } from 'vue'
import {
  defineComponent, computed
} from 'vue'
import MessageStatus from '@/components/pages/history/MessageStatus.vue'
import SmsSentDetailsSingleButtons from '@/components/pages/history/smsSent/details/SmsSentDetailsSingleButtons.vue'
import DetailsTable from '@/components/shared/templates/details/DetailsTable.vue'
import DetailsTableItem from '@/components/shared/templates/details/DetailsTableItem.vue'
import TmCountry from '@/components/shared/TmCountry.vue'
import SmsSentDetailsMessage from '@/components/pages/history/smsSent/details/SmsSentDetailsMessage.vue'
import TmListChipRecipient from '@/components/shared/TmListChipRecipient.vue'
import type { SMSReceivedSent } from '@/definitions/history/types'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    SmsSentDetailsSingleButtons,
    DetailsTable,
    MessageStatus,
    SmsSentDetailsMessage,
    DetailsTableItem,
    TmCountry,
    TmListChipRecipient,
  },
  props: {
    message: {
      type: Object as PropType<SMSReceivedSent>,
      required: true,
    },
    hideStatus: {
      type: Boolean,
    },
  },
  setup(props) {
    const isMMS = computed(() => typeof props.message.message === 'object' && props.message.message.extension === 'png')
    const isVoice = computed(() => typeof props.message.message === 'object' && props.message.message.extension === 'mp3')
    const isPlain = computed(() => typeof props.message.message === 'string')
    const encoding = computed(() => {
      if (isMMS.value) { return 'MMS' }
      if (isVoice.value) { return 'Voice' }
      return 'Plain text'
    })
    const { openModal } = useModals()
    const openImageAttachmentModal = (url: string) => {
      openModal('chatsImageAttachment', {
        url: url,
      })
    }

    return {
      encoding,
      isPlain,
      isVoice,
      isMMS,
      openImageAttachmentModal,
    }
  },
})
