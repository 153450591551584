
import { defineComponent, ref, watch } from 'vue'
import type { PropType } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import useContactsOpenModal from '@/compositions/contacts/useContactsOpenModal'
import useHistorySmsOpenModal from '@/compositions/history/useHistorySmsOpenModal'
import useBlockedOpenModal from '@/compositions/contacts/blocked/useBlockedOpenModal'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    TmDropdownItem,
  },
  props: {
    type: {
      type: String as PropType<'sent' | 'received'>,
      default: 'sent',
    },
  },
  setup(props) {
    const { openBlockContactModal, openUnsubscribeContactModal, openResubscribeContactModal } = useContactsOpenModal()
    const { openModalUnblock } = useBlockedOpenModal()
    const { isErrorMode } = useModes()

    const isSubscribed = ref(!isErrorMode.value)
    const isBlocked = ref(isErrorMode.value)

    const { openHistoryDeleteModal } = useHistorySmsOpenModal({
      lines: ref([]),
      type: props.type,
    })

    const handleSubscribe = () => {
      if (isSubscribed.value) {
        openUnsubscribeContactModal()
      } else {
        openResubscribeContactModal()
      }

      isSubscribed.value = !isSubscribed.value
    }

    const handleBlock = () => {
      if (isBlocked.value) {
        openModalUnblock('John Doe')
      } else {
        openBlockContactModal('John Doe')
      }
      isBlocked.value = !isBlocked.value
    }

    watch(() => isErrorMode.value, (val) => {
      if (val) {
        isSubscribed.value = false
        isBlocked.value = true
      }
    })

    return {
      handleSubscribe,
      handleBlock,
      openHistoryDeleteModal,
      isSubscribed,
      isBlocked,
    }
  },
})
