
import type { PropType } from 'vue'
import { defineComponent, ref, toRefs } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TmDropdownDownload from '@/components/shared/dropdowns/TmDropdownDownload.vue'
import useHistorySmsOpenModal from '@/compositions/history/useHistorySmsOpenModal'
import type { SMSReceivedSent } from '@/definitions/history/types'
import TopFilterButtonFilter from '@/components/layout/topFilter/TopFilterButtonFilter.vue'

export default defineComponent({
  components: {
    TopFilterButtonFilter,
    TopFilter,
    TmDropdownDownload,
  },
  props: {
    message: {
      type: Object as PropType<SMSReceivedSent>,
      required: true,
    },
  },
  setup(props) {
    const { message } = toRefs(props)
    const search = ref('')

    const { openHistoryFilterSms } = useHistorySmsOpenModal({
      lines: message,
      type: 'sent',
    })

    return {
      search,
      openHistoryFilterSms,
    }
  },
})
