import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_sms_recipient = _resolveComponent("tm-sms-recipient")!
  const _component_skeleton_person = _resolveComponent("skeleton-person")!
  const _component_tm_badge = _resolveComponent("tm-badge")!
  const _component_tm_skeleton = _resolveComponent("tm-skeleton")!
  const _component_message_status = _resolveComponent("message-status")!
  const _component_sms_sent_dropdown = _resolveComponent("sms-sent-dropdown")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, {
    headers: _ctx.headers,
    items: _ctx.items,
    "items-name": _ctx.itemsName,
    "show-selection": _ctx.showSelection,
    expand: _ctx.expand
  }, {
    "body-cell-to-slot": _withCtx((props) => [
      (!_ctx.isLoadingMode)
        ? (_openBlock(), _createBlock(_component_tm_sms_recipient, {
            key: 0,
            content: props.row.to,
            to: _ctx.getTo(props.row.id),
            class: _normalizeClass(["blue-on-hover", _ctx.detailsModal && 'pointer']),
            onClick: ($event: any) => (_ctx.detailsModal && _ctx.openModalFunc(props.row))
          }, null, 8, ["content", "to", "class", "onClick"]))
        : (_openBlock(), _createBlock(_component_skeleton_person, { key: 1 }))
    ]),
    "body-cell-message-slot": _withCtx((props) => [
      (!_ctx.isLoadingMode)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (typeof props.row.message === 'object')
              ? (_openBlock(), _createBlock(_component_tm_badge, {
                  key: 0,
                  label: `${props.row.message.name}.${props.row.message.extension}`,
                  icon: "tmi-people"
                }, null, 8, ["label"]))
              : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(props.row.message), 1))
          ], 64))
        : (_openBlock(), _createBlock(_component_tm_skeleton, {
            key: 1,
            type: "QChip",
            "random-width": "",
            height: "12px"
          }))
    ]),
    "body-cell-status-slot": _withCtx((props) => [
      (!_ctx.isLoadingMode)
        ? (_openBlock(), _createBlock(_component_message_status, {
            key: 0,
            status: props.row.status,
            plain: "",
            recipients: props.row.to.type === 'recipients'
          }, null, 8, ["status", "recipients"]))
        : (_openBlock(), _createBlock(_component_tm_skeleton, {
            key: 1,
            type: "QChip",
            height: "20px",
            width: "60px"
          }))
    ]),
    "body-cell-actions": _withCtx((props) => [
      (!_ctx.isLoadingMode)
        ? (_openBlock(), _createBlock(_component_sms_sent_dropdown, {
            key: 0,
            sms: props.row
          }, null, 8, ["sms"]))
        : (_openBlock(), _createBlock(_component_tm_skeleton, {
            key: 1,
            type: "circle",
            width: "16px",
            height: "16px"
          }))
    ]),
    _: 1
  }, 8, ["headers", "items", "items-name", "show-selection", "expand"]))
}