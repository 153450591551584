
import type { PropType } from 'vue'
import { defineComponent, toRefs, computed } from 'vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import useHistorySmsOpenModal from '@/compositions/history/useHistorySmsOpenModal'
import type { SMSReceivedSent } from '@/definitions/history/types'

export default defineComponent({
  components: {
    TmDropdown,
    TmDropdownItem,
    TmTableActionButton,
  },
  props: {
    sms: {
      type: Object as PropType<SMSReceivedSent>,
      required: true,
    },
  },
  setup(props) {
    const { sms } = toRefs(props)

    const showViewChatContactDownload = computed(() => sms.value.to.type !== 'recipients')
    const showEditTemplate = computed(() => (!['failed', 'sent'].includes(sms.value.status.name)))

    const { openHistoryDeleteModal } = useHistorySmsOpenModal({
      lines: sms,
      type: 'sent',
    })

    return {
      showViewChatContactDownload,
      showEditTemplate,
      openHistoryDeleteModal,
    }
  },
})
