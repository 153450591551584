
import type { PropType } from 'vue'
import { computed, defineComponent, ref } from 'vue'
import TmTabsPanels from '@/components/shared/TmTabsPanels.vue'
import TmTabsNav from '@/components/shared/TmTabsNav.vue'
import type { SizeProp, TabStyle } from '@/definitions/shared/types'
import type { RouteLocationRaw } from 'vue-router'

export type Tab = {
  name: string;
  counter?: number;
  label?: string;
  to?: RouteLocationRaw;
}

export default defineComponent({
  name: 'TmTabs',
  components: {
    TmTabsPanels,
    TmTabsNav,
  },
  props: {
    tabs: {
      type: Array as PropType<Tab[]>,
      required: true,
    },
    tabsNavClass: {
      type: String,
    },
    modelValue: {
      type: String,
      default: null,
    },
    maxVisibleItems: {
      type: Number,
    },
    withDropdown: {
      type: Boolean,
    },
    useRouter: {
      type: Boolean,
    },
    noBorder: {
      type: Boolean,
    },
    tabStyle: {
      type: String as PropType<TabStyle>,
    },
    hidePanels: {
      type: Boolean,
    },
    size: {
      type: String as SizeProp<'' | 'small'>,
      default: '',
    },
    tabsGrow: {
      type: Boolean,
    },
    vertical: {
      type: Boolean,
    },
    showCounterZero: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const state = ref(props.tabs.length ? props.tabs[0].name : '')
    const hasTabsNavRightSlot = !!context.slots['tabs-nav-right']

    const tabComponent = computed(() => {
      return props.useRouter ? 'q-route-tab' : 'q-tab'
    })

    return {
      state,
      tabComponent,
      hasTabsNavRightSlot,
    }
  },
})
