import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_table_action_button = _resolveComponent("tm-table-action-button")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createBlock(_component_tm_dropdown, null, {
    menu: _withCtx(() => [
      (_ctx.showViewChatContactDownload)
        ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
            key: 0,
            icon: "forum",
            label: "View chat"
          }))
        : _createCommentVNode("", true),
      (_ctx.showViewChatContactDownload)
        ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
            key: 1,
            icon: "person",
            label: "View contact"
          }))
        : _createCommentVNode("", true),
      (_ctx.showEditTemplate)
        ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
            key: 2,
            icon: "edit",
            label: "Edit and resend"
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_tm_dropdown_item, {
        icon: "visibility",
        label: "View details"
      }),
      (_ctx.showEditTemplate)
        ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
            key: 3,
            icon: "assignment",
            label: "Save as template"
          }))
        : _createCommentVNode("", true),
      (_ctx.showViewChatContactDownload)
        ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
            key: 4,
            icon: "download",
            label: "Download PDF"
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_tm_dropdown_item, {
        icon: "delete",
        label: "Delete message",
        onClick: _ctx.openHistoryDeleteModal
      }, null, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_tm_table_action_button)
    ]),
    _: 1
  }))
}